import { join } from 'lodash/fp';
import compact from 'lodash/fp/compact';
import isNil from 'lodash/fp/isNil';

import formatDistance from 'date-fns/formatDistance';
import dayjs from 'dayjs';

import {
  GroupedJobPreferencesData,
  SupportedJobPreferenceData,
  TEducationHistory,
  TEmploymentHistory,
} from '../types';

export const formatMonth = (month: number | undefined | null) =>
  month
    ? dayjs()
        .month(month - 1)
        .format('MMM')
        .toString()
    : '';

export const formatNumberMonth = (month: number | undefined | null) =>
  month
    ? dayjs()
        .month(month - 1)
        .format('MM')
        .toString()
    : '';

export const getFullDate = (
  day: number | undefined | null,
  month: number | undefined | null,
  year: number | undefined | null
) => {
  if (day && month && year) {
    return `${day} ${formatMonth(month)} ${year}`;
  }

  if (month && year) {
    return `${formatMonth(month)} ${year}`;
  }

  if (year) {
    return `${year}`;
  }

  return 'Not provided';
};

export const countTime = (data: TEmploymentHistory | TEducationHistory) => {
  const { start_day, start_month, start_year, end_day, end_month, end_year } =
    data;
  const startTime = getFullDate(start_day || 1, start_month, start_year);
  const endTime = getFullDate(end_day || 1, end_month, end_year);

  if ([startTime, endTime].includes('Not provided')) return undefined;
  return formatDistance(new Date(startTime), new Date(endTime));
};

export const getPeriod = (data: TEmploymentHistory | TEducationHistory) => {
  const {
    start_day,
    start_month,
    start_year,
    end_day,
    end_month,
    end_year,
    current_job,
  } = data;

  const startTime = getFullDate(start_day, start_month, start_year);
  const endTime = getFullDate(end_day, end_month, end_year);

  if (current_job) {
    return `${
      startTime !== 'Not provided' ? startTime : 'Start date (Not provided)'
    } - Present`;
  }

  return `${
    startTime !== 'Not provided' ? startTime : 'Start date (Not provided)'
  } - ${endTime !== 'Not provided' ? endTime : 'Present'}`;
};

export const formatAddress = (
  city?: string | null,
  state?: string | null,
  country?: string
) => {
  if (isNil(city) && isNil(state) && isNil(country)) {
    return 'Not provided';
  }

  return compact([city, state, country]).join(', ');
};

// convert string date to number day, month & year
export const processDayMonthYearParams = (
  startDate: string,
  endDate: string
) => {
  const startDateArr = startDate ? startDate.split('-') : [null, null, null];
  const endDateArr = endDate ? endDate.split('-') : [null, null, null];

  return {
    // eslint-disable-next-line radix
    startDay: startDateArr[2] ? parseInt(startDateArr[2]) : null,
    // eslint-disable-next-line radix
    startMonth: startDateArr[1] ? parseInt(startDateArr[1]) : null,
    // eslint-disable-next-line radix
    startYear: startDateArr[0] ? parseInt(startDateArr[0]) : null,
    // eslint-disable-next-line radix
    endDay: endDateArr[2] ? parseInt(endDateArr[2]) : null,
    // eslint-disable-next-line radix
    endMonth: endDateArr[1] ? parseInt(endDateArr[1]) : null,
    // eslint-disable-next-line radix
    endYear: endDateArr[0] ? parseInt(endDateArr[0]) : null,
  };
};

export const getInitialSelectedDate = (
  day?: number | null,
  month?: number | null,
  year?: number | null,
  shouldHideDay = false
) => {
  if (month && year && shouldHideDay) {
    return `${year}-${formatNumberMonth(month)}`;
  }

  if (day && month && year) {
    return `${year}-${formatNumberMonth(month)}-${day}`;
  }

  if (month && year) {
    return `${year}-${formatNumberMonth(month)}-01`;
  }

  if (year) {
    return `${year}-01-01`;
  }

  return undefined;
};

export const groupJobPreferences = (
  jobPreferences: Array<SupportedJobPreferenceData>
): GroupedJobPreferencesData =>
  jobPreferences.reduce<GroupedJobPreferencesData>(
    (groupedJobPreferences, jobPreference) => ({
      ...groupedJobPreferences,
      StandardJobInfo:
        jobPreference.preferable_type === 'StandardJobInfo'
          ? [...groupedJobPreferences.StandardJobInfo, jobPreference]
          : groupedJobPreferences.StandardJobInfo,
      JobTitle:
        jobPreference.preferable_type === 'JobTitle'
          ? [...groupedJobPreferences.JobTitle, jobPreference]
          : groupedJobPreferences.JobTitle,
      IdibuLocation:
        jobPreference.preferable_type === 'IdibuLocation'
          ? [...groupedJobPreferences.IdibuLocation, jobPreference]
          : groupedJobPreferences.IdibuLocation,
      SalaryInfo:
        jobPreference.preferable_type === 'SalaryInfo'
          ? [...groupedJobPreferences.SalaryInfo, jobPreference]
          : groupedJobPreferences.SalaryInfo,
      WorkTypePreference:
        jobPreference.preferable_type === 'WorkTypePreference'
          ? [...groupedJobPreferences.WorkTypePreference, jobPreference]
          : groupedJobPreferences.WorkTypePreference,
      WorkplaceTypePreference:
        jobPreference.preferable_type === 'WorkplaceTypePreference'
          ? [...groupedJobPreferences.WorkplaceTypePreference, jobPreference]
          : groupedJobPreferences.WorkplaceTypePreference,
      AvailabilityToStart:
        jobPreference.preferable_type === 'AvailabilityToStart'
          ? [...groupedJobPreferences.AvailabilityToStart, jobPreference]
          : groupedJobPreferences.AvailabilityToStart,
      AvailableDay:
        jobPreference.preferable_type === 'AvailableDay'
          ? [...groupedJobPreferences.AvailableDay, jobPreference]
          : groupedJobPreferences.AvailableDay,
      AvailableTime:
        jobPreference.preferable_type === 'AvailableTime'
          ? [...groupedJobPreferences.AvailableTime, jobPreference]
          : groupedJobPreferences.AvailableTime,
    }),
    {
      StandardJobInfo: [],
      JobTitle: [],
      IdibuLocation: [],
      VendorLocation: [],
      SalaryInfo: [],
      WorkTypePreference: [],
      WorkplaceTypePreference: [],
      AvailabilityToStart: [],
      AvailableDay: [],
      AvailableTime: [],
    }
  );

export const formatName = (firstName?: string, lastName?: string): string => {
  const trimmedFirstName = firstName?.trim();
  const trimmedLastName = lastName?.trim();

  return join(
    ' ',
    compact([
      trimmedFirstName,
      trimmedLastName ? trimmedLastName.charAt(0) : undefined,
    ])
  );
};
